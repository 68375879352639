import React, { lazy, Suspense, useState, useEffect } from 'react';
import Landing from './sections/Landing/Landing.jsx';
import Introduction from './sections/Introduction/Introduction.jsx';
import About from './sections/About/About.jsx';
import Clients from './sections/Clients/Clients.jsx';
import './home.css';

// Example of lazy-loading Spline (if needed)
const LazySpline = lazy(() => import('@splinetool/react-spline'));

function Home() {
    // Delay mounting the Spline scene to allow the main page to load first.
    const [loadSpline, setLoadSpline] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadSpline(true);
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='main'>

            {loadSpline && (
                <div className='spline-resize'>
                    <Suspense fallback={null}>
                        <LazySpline
                            className="spline-scene-home"
                            scene="https://prod.spline.design/8KVQV84BUBO4ODVt/scene.splinecode"
                        />
                    </Suspense>
                </div>
            )}


            <section id='landing'>
                <Landing />
            </section>

            <section id='introduction'>
                <Introduction />
            </section>

            <section id='about'>
                <About />
            </section>

            <section id='clients'>
                <Clients />
            </section>
        </div>
    );
}

export default Home;
