import React, { lazy, Suspense, useState, useEffect } from 'react';
import './introduction.css';

function Introduction() {
    // Lazy-load the Spline component
    const LazySpline = lazy(() => import('@splinetool/react-spline'));
    const [loadSpline, setLoadSpline] = useState(false);

    // Slight delay before loading Spline
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadSpline(true);
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <section className="introduction">
            <div className="intro-elements">
                <div className="intro-text">
                    We secure the legal,<br /> you power the tech.
                </div>

                {loadSpline && (
                    <div className="spline-resize">
                        <Suspense fallback={null}>
                            <LazySpline
                                className="spline-scene-intro"
                                scene="https://prod.spline.design/F-zJmawgpj-sHVcn/scene.splinecode"
                            />
                        </Suspense>
                    </div>
                )}
            </div>
        </section>
    );
}

export default Introduction;
