import React, { useEffect, useState } from "react";
import './landing.css';
import { useNavigate } from "react-router-dom";

function Landing() {
    const navigate = useNavigate();

    // Track whether screen width is < 500px
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

    useEffect(() => {
        // Handler to update state on resize
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 500);
        };

        // Attach the listener
        window.addEventListener("resize", handleResize);

        // Cleanup the listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <section id="landing" className="landing">
            <div className="landing-elements">

                {isSmallScreen ? (
                    <>
                        <div className="landing-title">
                            Orbilaw
                        </div>

                        <div
                            className="powered-by"
                            onClick={() => window.open("https://www.belhommelaw.com/", "_blank")}
                        >
                            Powered By Belhomme Law
                        </div>
                    </>
                ) : (
                    <div className="landing-title">
                        Orbi
                        <span className="law-part">
                            law
                            <div
                                className="powered-by"
                                onClick={() => window.open("https://www.belhommelaw.com/", "_blank")}
                            >
                                Powered By Belhomme Law
                            </div>
                        </span>
                    </div>
                )}

                <div className="landing-middle">
                    Powering Web3 projects with legal excellence
                </div>

                <button onClick={() => navigate('/services')} className="landing-button">
                    Secure my project
                </button>
            </div>
        </section>
    );
}

export default Landing;
