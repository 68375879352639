import React from "react";
import './clients.css';
import KaminoImg from '../../../../assets/images/logo-f4FieaHQ.svg';
import R3genImg from '../../../../assets/images/r3gen.png';
import BelhommelawImg from '../../../../assets/images/Belhomme+Law+Logo+White+1.png';

function CarouselItem({ imgUrl, imgTitle, url }) {
    return (
        <div className="carousel-card">
            <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={imgUrl} alt={imgTitle} />
            </a>
        </div>
    );
}

// Reduced triple duplication to single duplication
const cardDetails = [
    { imgUrl: KaminoImg, title: "Kamino", url: "https://app.kamino.finance/" },
    { imgUrl: R3genImg, title: "R3gen Finance", url: "https://www.r3gen.finance/" },
    { imgUrl: BelhommelawImg, title: "Belhomme Law", url: "https://belhommelaw.com" }
];

// Duplicate only once to keep infinite scroll feel
const allCards = [...cardDetails, ...cardDetails];

function Clients() {
    return (
        <div className="clients-section">
            <div className="clients-title">Our Partners</div>
            <div className="carousel-container">
                <div className="carousel-track">
                    {allCards.map((card, index) => (
                        <CarouselItem
                            key={index}
                            imgUrl={card.imgUrl}
                            imgTitle={card.title}
                            url={card.url}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Clients;
