import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./services.css";

const servicesData = [
    {
        title: "Tax Optimisation (Global)",
        description:
            "As international tax and structuring experts, we are able to comment on how you can be tax optimised over multiple-jurisdictions and can bring in specific subject experts to comment on any specific points that we identify you need to be aware of.",
        pricing: "From USD5,000",
    },
    {
        title: "Financial Reporting (Global)",
        description:
            "Leveraging proprietary technology we have an innovative way to bookkeep and produce financial statements that comply with not only IFRS and the legal jurisdiction they are being prepared for, but also keep in line with your legal and tax strategy.",
        pricing: "From USD2,500 (up to 2 wallets & 1,500 txns)",
    },
    {
        title: "Company Incorporation",
        description:
            "We will not only incorporate your entity for you, but we go a step further with tax analysis, shareholder agreements, IP assignments, and key legal templates needed to ensure your fundraising goes smoothly.",
        pricing: "From USD3,000",
    },
    {
        title: "Regulatory Compliance",
        description:
            "We ensure that you are aware of and fully complying with any global regulatory and licensing requirements your business may be subject to.",
        pricing: "From USD3,000",
    },
    {
        title: "Intellectual Property Protection",
        description:
            "We ensure your most important asset is protected with a multi-year IP strategy. This involves proper documentation and accounting for intangible assets.",
        pricing: "From USD2,500 up to USD22,000 (full strategy)",
    },
    {
        title: "Decentralisation & Governance",
        description:
            "We facilitate gentle step-by-step decentralisation, including building DAO legal wrappers, oversight committees, and robust governance systems.",
        pricing: "From USD2,500 (Year 1)",
    },
    {
        title: "Outsourced General Counsel",
        description:
            "Sometimes you just need someone on your side. We act as an outsourced General Counsel who can jump in whenever you need it.",
        pricing: "From USD5,000 (per month)",
    },
    {
        title: "Fundraising",
        description:
            "We provide custom paperwork such as SAFEs, SAFTs, Investor Agreements, Token Warrants and more—common in Web3 fundraising methods.",
        pricing: "From USD3,500 (per round)",
    },
    {
        title: "Emergency Housekeeping Review & Implementation",
        description:
            "For more mature projects that might have grown quickly, we perform a deep audit of your legal, tax, regulatory, and governance positions, then implement a plan to fix any issues.",
        pricing: "USD4,800 (one-off)",
    },
];

const ServiceCard = ({ serviceItem, onMoreInfo, onOrder }) => {
    return (
        <div className="service-card">
            <h2 className="card-title">{serviceItem.title}</h2>
            <p className="card-description collapsed">
                {serviceItem.description}
            </p>
            <p className="card-pricing">
                <strong>Pricing:</strong> {serviceItem.pricing}
            </p>
            <div className="card-button-row">
                <button
                    className="card-button"
                    onClick={() => onMoreInfo(serviceItem)}
                >
                    More Info
                </button>
                {/* Use an arrow function to ensure navigation happens on click */}
                <button
                    className="card-button"
                    onClick={onOrder}
                >
                    Order
                </button>
            </div>
        </div>
    );
};

const ServiceModal = ({ service, onClose, onOrder }) => {
    if (!service) return null;

    return (
        <AnimatePresence>
            <motion.div
                className="modal-backdrop"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <motion.div
                    className="modal-content"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                >
                    <h2 className="modal-title">{service.title}</h2>
                    <p className="modal-description">{service.description}</p>
                    <p className="modal-pricing">
                        <strong>Pricing:</strong> {service.pricing}
                    </p>

                    <div className="modal-button-row">
                        <button className="modal-button" onClick={onClose}>
                            Back
                        </button>
                        {/* Also wrap navigate in an arrow function here */}
                        <button className="modal-button" onClick={onOrder}>
                            Order
                        </button>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

const Services = () => {
    const navigate = useNavigate();
    const [selectedService, setSelectedService] = useState(null);

    const handleMoreInfo = (serviceItem) => {
        setSelectedService(serviceItem);
    };

    const handleCloseModal = () => {
        setSelectedService(null);
    };

    const handleOrder = () => {
        navigate("/contact");
    };

    return (
        <div className="services-container">
            <div className="services-page">
                <motion.h1
                    className="services-title"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    OrbiServices
                </motion.h1>

                <motion.div
                    className="services-grid"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                >
                    {servicesData.map((item, idx) => (
                        <ServiceCard
                            key={idx}
                            serviceItem={item}
                            onMoreInfo={handleMoreInfo}
                            onOrder={handleOrder}
                        />
                    ))}
                </motion.div>

                {selectedService && (
                    <ServiceModal
                        service={selectedService}
                        onClose={handleCloseModal}
                        onOrder={handleOrder}
                    />
                )}
            </div>
        </div>
    );
};

export default Services;
